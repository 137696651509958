import React from 'react'
import { connect } from 'react-redux'
import { IntegrationError } from 'shared/ui'
import { selectCurrentCustomer } from 'selectors/tickets/customer/selectCurrentCustomer'
import {
  selectSidebarCardData,
  selectIsSidebarCardFailed,
  selectIsSidebarCardLoading,
  selectIsSidebarCardExpired,
} from 'selectors/sidebar_cards'
import { runOnNextTick } from 'util/functions'

import InAppCard from './shared/InAppCard'

export default function withCardDataLoading(WrappedComponent, options = {}) {
  const { useInAppCardView = true } = options
  let select = null
  const selectFactory = (initialState, initialOwnProps) => {
    const { id: cardId } = initialOwnProps
    return state => ({
      customer: selectCurrentCustomer(state),
      data: selectSidebarCardData(state)(cardId) || initialOwnProps.data,
      isFailed: selectIsSidebarCardFailed(state)(cardId),
      isLoading: selectIsSidebarCardLoading(state)(cardId),
      isExpired: selectIsSidebarCardExpired(state)(cardId),
    })
  }
  if (!options.skipSelectPhase) select = selectFactory

  class CardDataLoading extends React.PureComponent {
    componentDidMount() {
      this.onLoad()
    }

    componentDidUpdate(prevProps) {
      const { customerId, contactId, isExpired } = this.props
      if (!customerId && !contactId) return
      if (
        prevProps.customerId !== customerId ||
        prevProps.contactId !== contactId ||
        isExpired
      ) {
        this.onLoad()
      }
    }

    onLoad = () => {
      const { load, customerId, contactId } = this.props
      if (load) runOnNextTick(() => load(customerId || contactId))
    }

    onReload = e => {
      e.stopPropagation()
      const { load, customerId, hardRefreshOnReload } = this.props
      if (load) {
        runOnNextTick(() =>
          load(customerId, { isReload: true, hardRefresh: hardRefreshOnReload })
        )
      }
    }

    render() {
      const {
        data,
        errorMessage,
        id,
        image,
        isFailed,
        isLoading,
        load,
        openStateKey,
        title,
      } = this.props

      if (useInAppCardView === false) {
        return (
          <WrappedComponent
            key={id}
            {...this.props}
            canReload={options.canReload}
            onReload={this.onReload}
          />
        )
      }
      if (!data && isLoading)
        return (
          <InAppCard
            image={image}
            isLoading
            title={title}
            onReload={this.onReload}
          />
        )
      if (isFailed) {
        return (
          <InAppCard
            image={image}
            key={openStateKey}
            openStateKey={openStateKey}
            title={title}
            onReload={this.onReload}
          >
            <IntegrationError onRetry={load} message={errorMessage} />
          </InAppCard>
        )
      }
      if (!data) return null
      return (
        <InAppCard
          image={image}
          key={openStateKey}
          openStateKey={openStateKey}
          title={title}
          onReload={options.canReload !== false && this.onReload}
          className={options.cardClassName}
        >
          <WrappedComponent key={this.props.id} {...this.props} />
        </InAppCard>
      )
    }
  }

  const wrappingComponent = connect(select)(CardDataLoading)

  wrappingComponent.displayName = `withCardDataLoading(${WrappedComponent.displayName ||
    WrappedComponent.name})`
  return wrappingComponent
}
