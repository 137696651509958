import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Accordion from '@groovehq/internal-design-system/lib/components/Accordion/Accordion'
import { LANDING_PAGE as KB_LANDING_PAGE } from 'subapps/kb/pages'
import { doOpenMainPage } from 'actions/pages'
import { REPORTS_MAIN_PAGE } from 'ducks/reports/pages'
import { selectReportingPermitted } from 'ducks/currentUser/selectors/selectReportingPermitted'
import { AI_AGENT_PAGE } from 'constants/pages'
import { INDEX_PAGE as CRM_LANDING_PAGE } from 'ducks/crm/pages'
import { isFeatureEnabled } from 'ducks/tracking/actions'

import { styles } from './styles'
import Item from '../Nav/Item'

const toMainPage = doOpenMainPage()
const toAIAgentPage = { type: AI_AGENT_PAGE }
const toReportsPage = { type: REPORTS_MAIN_PAGE }
const toKbLandingPage = { type: KB_LANDING_PAGE }
const toCrmContactsPage = { type: CRM_LANDING_PAGE }

const AccordionNav = ({ active, setOpen }) => {
  const showReporting = useSelector(selectReportingPermitted)
  const showCustomers = isFeatureEnabled('crm-contacts-page-enabled')

  const handleCloseMenu = useCallback(
    () => {
      setOpen(false)
    },
    [setOpen]
  )

  return (
    <Accordion css={styles.accordionNav}>
      <Accordion.Panel
        panelKey={'conversations'}
        title={
          <Item
            css={text.styles.textXs}
            isActive={active === 'Conversations'}
            to={toMainPage}
            className="grui p-0 m-0"
            onClick={handleCloseMenu}
          >
            Conversations
          </Item>
        }
        css={styles.accordionPanelLink}
      />
      <Accordion.Panel
        panelKey={'aiAgent'}
        title={
          <Item
            css={text.styles.textXs}
            isActive={active === 'AI Agent'}
            to={toAIAgentPage}
            className="grui p-0 m-0"
            onClick={handleCloseMenu}
          >
            AI Agent
          </Item>
        }
        css={styles.accordionPanelLink}
      />
      {showReporting && (
        <Accordion.Panel
          panelKey={'reports'}
          title={
            <Item
              css={text.styles.textXs}
              isActive={active === 'Reporting'}
              to={toReportsPage}
              className="grui p-0 m-0"
              onClick={handleCloseMenu}
            >
              Reports
            </Item>
          }
          css={styles.accordionPanelLink}
        />
      )}
      <Accordion.Panel
        panelKey={'knowledge'}
        title={
          <Item
            css={text.styles.textXs}
            isActive={active === 'Knowledge Base'}
            to={toKbLandingPage}
            className="grui p-0 m-0"
            onClick={handleCloseMenu}
          >
            Knowledge Base
          </Item>
        }
        css={styles.accordionPanelLink}
      />
      {showCustomers && (
        <Accordion.Panel
          panelKey={'customers'}
          title={
            <Item
              css={text.styles.textXs}
              isActive={active === 'Customers'}
              to={toCrmContactsPage}
              className="grui p-0 m-0"
              onClick={handleCloseMenu}
            >
              Customers
            </Item>
          }
          css={styles.accordionPanelLink}
        />
      )}
    </Accordion>
  )
}

export default AccordionNav
